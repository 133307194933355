import React from 'react'

import Seo from '../components/seo'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import Footer from '../components/Footer'

function Page(props) {

  const [refWork, refWorkInView] = useInView({ triggerOnce: true })

  return (
    <>

      <MainFrameCover />

      <main id="mainframe" className={`active main-home`}>

        <Seo title={"Meble ze stali na wymiar, drzwi loftowe"} siteTitle={'Stalowa Rączka'} description={"Drzwi, Ścianki, Balustrady, Ogrodzenia, Meble, Tarasy. Kompleksowe rozwiązania przestrzeni loft. Indywidualne projekty na zamówienie."} path={props.location?.pathname ? props.location?.pathname : ''} />

        <section ref={refWork} className="section-work-new c5 padd padd-top-2 padd-bottom-2 ">

          <Intro visible={refWorkInView} in={{ fade: 750 }} delayIn={500} mounted={true} stay={true} className={``}>

            <div class="wysiwyg-inject">
              <h1 style={{ fontSize: "5em" }}>Praca od zaraz</h1>
              <h2>pomocnik / ślusarz / spawacz</h2>
              <p>W chwili obecnej poszukujemy pracownika na stanowisko pomocnika, ślusarza lub spawacza w
                warsztacie. Praca na etat lub część.</p>
              <p>Wymagane:</p>
              <ul>
                <li>zaangażowanie i chęć uczenia się w przypadku braku kompetencji spawania czy obróbki
                  metalu</li>
                <li>dostępność czasowa</li>
                <li>kreatywne podejście do rozwiązywania problemów</li>
              </ul>
              <p>Oferujemy:</p>
              <ul>
                <li>pracę przy produkcji oraz instalacji u klientów konstrukcji loftowych oraz mebli industrialnych</li>
                <li>wynagrodzenie godzinowe</li>
                <li>pracę bez przestojów</li>
                <li>pracę w SIEDLCACH + wyjazdy na montaże i zaopatrzenie</li>
                <li>Na początek umowę zlecenie lub B2B</li>
              </ul>
              <p>Napisz do nas przez formularz lub bezpośrednio na adres:&nbsp; <a href="mailto:kamil.stalowa.raczka@gmail.com">kamil.stalowa.raczka@gmail.com</a></p>
              <p>lub zadzwoń: <a href="tel:508660710">508&nbsp;660 710</a></p>
            </div>

          </Intro>
        </section>


        <Footer />

      </main>

    </>
  )
}

export default Page